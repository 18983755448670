const deepMerge = require('deepmerge');

/**
 * Performs a deep merge of two objects.
 *
 * @param {*} obj1
 * @param {*} obj2
 */
export const merge = (obj1, obj2) => {
  return deepMerge(obj1, obj2);
};

/**
 * This method determines if an object is undefined or null.
 *
 * @param {Object} value
 */
export const isUndefined = (value) => {
  return typeof value === 'undefined' || value === null;
};

/**
 * This method will remove all trailing instances of a string
 * from a string. For example:
 *
 * str = '123/345/567/none/none'
 * trailStr = '/none'
 *
 * return = '123/345/567'
 *
 * Useful for constructing topic strings with correct hierarchy info.
 *
 * @param {string} str string to trim trailing strings from
 * @param {string} trailStr the trailing string to remove
 */
export const trimTrailing = (str, trailStr) => {
  let trimmed = str;
  while (trimmed.endsWith(trailStr)) {
    trimmed = trimmed.slice(0, -(trailStr.length));
  }
  return trimmed;
};

/**
 * Common debounce function, will wait the specified amount of time
 * until calling the function provided it has not been called again
 * during the wait window. In which case the timer will reset,
 *
 * @param {func} func function to call after the specified wait period has elapsed
 * @param {number} wait time in milliseconds to wait before calling a function.
 * @param {boolean} immediate if true, trigger the function on the leading edge
 */
export const debounce = (func, wait, immediate = false) => {
  let timeout;

  // This is the function that is actually executed when
  // the DOM event is triggered.
  return function executedFunction() {
    // Store the context of this and any
    // parameters passed to executedFunction
    let context = this;
    let args = arguments;

    // The function to be called after
    // the debounce time has elapsed
    let later = function() {
      // null timeout to indicate the debounce ended
      timeout = null;

      // Call function now if you did not on the leading end
      if (!immediate) func.apply(context, args);
    };

    // Determine if you should call the function
    // on the leading or trail end
    let callNow = immediate && !timeout;

    // This will reset the waiting every function execution.
    // This is the step that prevents the function from
    // being executed because it will never reach the
    // inside of the previous setTimeout
    clearTimeout(timeout);

    // Restart the debounce waiting period.
    // setTimeout returns a truthy value (it differs in web vs node)
    timeout = setTimeout(later, wait);

    // Call immediately if you're dong a leading
    // end execution
    if (callNow) func.apply(context, args);
  };
};

/**
 * Utility function to pick out query string parameters from a
 * URL string.
 *
 * @param {string} url url to parse for query parameters.
 */
export const getQueryStringParameters = (url) => {
  let regex = /[?&]([^=#]+)=([^&#]*)/g,
    params = {},
    match;

  while ((match = regex.exec(url))) {
    params[match[1]] = match[2];
  }

  return params;
};

export const genGUID = () => {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
}
