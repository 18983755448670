import logo from "./logo.svg";
import "./App.css";
import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  useRef,
} from "react";

import { LoadScript, GoogleMap } from "@react-google-maps/api";
import Geocoder from "react-geocode";
import { Images } from "./assets";
import MapElements from "./MapElements";
import config, { API_HOST } from "./config/config";
import {
  DrawElementConst,
  GoogleMapType,
  GoogleDrawType,
  ToolsButtons,
  calculateStrokeWeight,
  StrokeMinWeight,
} from "./mapUtil";
import buffer from "buffer";
import { DistanceUtil } from "./util/distance.util";

const containerStyle = {
  width: "80%",
  height: "500px",
};

function App(props) {
  const [map, setMap] = useState(null);
  const [mapReadOnly, setMapReadOnly] = useState(true);
  const [mapDataChange, setMapDataChange] = useState(false);
  const [drawData, setDrawData] = useState();
  const [tools, setTools] = useState(ToolsButtons);
  const [type, setType] = useState("");
  const [selectedDrawType, setSelectedDrawType] = useState(null);
  const [drawingManager, setDrawingManager] = useState(null);
  const [selected, setSelected] = useState(null);
  const [tabPage, setTabPage] = useState(0);
  const [editMode, setEditMode] = useState(DrawElementConst.EDIT);
  const [groups, setGroups] = useState(props.groups);
  const [groupsChanged, setGroupsChanged] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupEdit, setGroupEdit] = useState(false);
  const [siteData, setSiteDate] = useState({});
  const [codehandler, setcodehandler] = useState(
    document.location.href.substring(
      document.location.href.lastIndexOf("/") + 1,
      document.location.href.length
    )
  );
  const [center, setCenter] = useState({});
  const [oldZoomVal, setOldZoomVal] = useState(0);
  const [isLoading, setisLoading] = useState(true);
  const [zoom, setZoom] = useState(15);
  const [deletedItemId, setDeletedItemId] = useState(null);
  const [mapOptions, setMapOptions] = useState({
    mapTypeId: GoogleMapType.SATELLITE,
    mapTypeControl: false,
    draggable: true,
    scrollwheel: true,
    panControl: false,
    zoom: zoom,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
  });
  const [zoomedAreaSize, setZoomedAreaSize] = React.useState(0);
  const tabRef = useRef(null);
  const childRef = useRef();
  const childRefTopBar = useRef();
  const googleLibs = ["drawing", "places"];
  const [mounted, setMounted] = useState(false);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const getSiteData = () => {
    fetch(API_HOST + codehandler, {
      method: "GET",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setSiteDate(result);
          const newResult =
            Object.keys(result.siteMap).length === 0
              ? []
              : result.siteMap.Item.elements.map((e) => {
                  // const newIcon = Images[`${e.img}_OFF`];
                  const newIcon = {
                    url: Images[`${e.img}_OFF`],
                    labelOrigin: { x: 15, y: -10 },
                  };
                  var newTextNode = {};
                  if (e.textNode) {
                    newTextNode = {
                      position: e.textNode.center,
                      label: {
                        text: e.name,
                        color: "red",
                      },
                      icon: Images["LBL_IMG_ICO"],
                      id: `${e.id}_text`,
                    };
                  }
                  return { ...e, icon: newIcon };
                });
          setZoom(
            Object.keys(result.siteMap).length === 0
              ? 15
              : result.siteMap.Item.zoomLevel
          );

          setDrawData(
            Object.keys(result.siteMap).length === 0 ? [] : newResult
          );
          setCenter({
            lat:
              Object.keys(result.siteMap).length === 0
                ? Object.keys(result.siteAddress).length === 0
                  ? ""
                  : result.siteAddress.latitude
                : result.siteMap.Item.location.lat,
            lng:
              Object.keys(result.siteMap).length === 0
                ? Object.keys(result.siteAddress).length === 0
                  ? ""
                  : result.siteAddress.longitude
                : result.siteMap.Item.location.lng,
          });
          setisLoading(false);
        },
        (error) => {
          return console.log(error);
        }
      );
  };

  useEffect(() => {
    getSiteData();
  }, []);

  const onLoad = React.useCallback((map) => {
    //const bounds = new window.google.maps.LatLngBounds(center);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback((map) => {
    setMap(null);
  }, []);

  const onDrawManageLoad = (drawManager) => {
    setDrawingManager(drawManager);
  };

  const NavigateToMap = () => {
    // Search by address
    Geocoder.setApiKey("AIzaSyBU0_g8nGzHxsr-BQUuQvSZ3zuPBSKAFjg");
    var windowReference = window.open();
    Geocoder.fromAddress(siteData.siteAddress.place).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        console.log(lat, lng);
        windowReference.location =
          "https://maps.google.com?q=" + lat + "," + lng;
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const onSelectedItem = (element) => {
    element.selected = false;
  };
  if (isLoading) {
    return (
      <div className="fullCenter">
        <p style={{ textAlign: "center", color: "orange", fontSize: 16 }}>
          Loading ...
        </p>
      </div>
    );
  }
  var openLink = document.location.href.substring(
    0,
    document.location.href.lastIndexOf("/") + 1
  );

  const onZoomChange = () => {
    if (oldZoomVal === 0) {
      setOldZoomVal(zoom);
    }
    setZoom((map && map.getZoom()) || zoom);
  };

  const onIdleEvent = () => {
    setOldZoomVal(0);
    calcBounds();
  };

  function calcBounds() {
    if (map ? map.getBounds() : null) {
      setTimeout(() => {
        const bounds = map.getBounds();
        const sw = bounds.getSouthWest();
        const ne = bounds.getNorthEast();
        const southWest = new window.google.maps.LatLng(sw.lat(), sw.lng());
        const northEast = new window.google.maps.LatLng(ne.lat(), ne.lng());
        const southEast = new window.google.maps.LatLng(sw.lat(), ne.lng());
        const northWest = new window.google.maps.LatLng(ne.lat(), sw.lng());
        const SQM_Fraction = 0.00025;
        const calulatedArea = DistanceUtil.getAreaByPosition([
          northEast,
          northWest,
          southWest,
          southEast,
        ]);
        const totalArea = calulatedArea * SQM_Fraction;
        setZoomedAreaSize(DistanceUtil.roundWithDecimals(totalArea, 0));
        console.log("ViewPort Area:" + totalArea);
        childRef.current.onZoomChange({
          previousZoom: oldZoomVal,
          currentZoom: zoom,
        });
      }, 100);
    }
  }

  const onClickMap = () => {
    if (true) {
      setSelected(null);
      childRef.current.onSelectItem(null);
    }
  };

  const onDeleteItem = (e) => {
    const type = selected.type;
    const selectedDataSet = type === DrawElementConst.group ? groups : drawData;
    const deleted = selected.id;
    const slIndex = selectedDataSet.findIndex((x) => x.id === selected.id);
    if (slIndex > -1) {
      if (type === DrawElementConst.group) {
        props.onShowWarning({
          deletegroup: true,
        });
        setSelected(selected);
        setSelectedGroup(selected);
      } else {
        const tempData = Object.assign([], selectedDataSet);
        tempData.splice(slIndex, 1);
        setDeletedItemId(deleted);
        setSelected(null);
      }
      setMapDataChange(true);
    }
  };
  const deleteItemById = (itemId) => {
    onDeleteItem();
    setTimeout(() => {
      console.log("hit");
      onClickMap();
    });
  };

  return (
    <div className="mainContainer">
      <div className="subContainer">
        <p className="subText">{siteData.siteName ? siteData.siteName : ""}</p>
        <p style={{ textAlign: "right", fontSize: 16 }}>
          {siteData.siteAddress ? siteData.siteAddress.place : ""}
        </p>
      </div>
      <LoadScript
        googleMapsApiKey="AIzaSyBU0_g8nGzHxsr-BQUuQvSZ3zuPBSKAFjg"
        libraries={googleLibs}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          options={mapOptions}
          center={center}
          zoom={zoom}
          onZoomChanged={onZoomChange}
          onDragStart={onZoomChange}
          onIdle={onIdleEvent}
          onLoad={onLoad}
          onUnmount={onUnmount}
          //onClick={onClickMap}
        >
          <MapElements
            ref={childRef}
            drawData={drawData}
            map={map}
            readOnly={mapReadOnly}
            mode={editMode}
            drawType={type}
            selectedGroup={selectedGroup}
            selectedItem={selected}
            onShowWarning={() => {}}
            deletedItemId={deletedItemId}
            isAnyGroupVisible={() => {}}
            onSelected={onSelectedItem}
            onChangeComplete={() => {}}
            onDrawManage={() => {}}
            groupEdit={true}
            //onDrawManage={onDrawManageLoad}
            onDeleteItemById={deleteItemById}
          />
        </GoogleMap>

        <div className="subContainer">
          <div>
            <button
              className="btnStyle"
              onClick={(e) => {
                e.preventDefault();
                window.open(siteData.url);
              }}
            >
              OPEN NEXUS APP
            </button>
            <button
              className="btnStyle addMargin"
              onClick={() => NavigateToMap()}
              // style={{ marginLeft: 20, marginRight: 20 }}
            >
              OPEN IN GOOGLE MAPS
            </button>
          </div>
          <div style={{ fontSize: 14, alignItems: "flex-end" }}>
            <p style={{ textAlign: "right", marginTop: "-1%" }}>
              Site Manager :{" "}
              {siteData.siteManager ? siteData.siteManager.name : ""}
            </p>
            <p style={{ textAlign: "right", marginTop: "-5%", color: "black" }}>
              <a
                style={{ color: "black" }}
                href={`tel:${siteData.siteManager.phone}`}
              >
                {siteData.siteManager
                  ? " " +
                    siteData.siteManager.phone.substring(0, 2) +
                    " (" +
                    siteData.siteManager.phone.substring(2, 5) +
                    ") " +
                    siteData.siteManager.phone.substring(5, 8) +
                    "-" +
                    siteData.siteManager.phone.substring(8, 12)
                  : ""}
              </a>
            </p>
            <p style={{ textAlign: "right", marginTop: "-5%", color: "black" }}>
              <a
                style={{ color: "black" }}
                href={`mailto:${siteData.siteManager.email}`}
              >
                {siteData.siteManager ? siteData.siteManager.email : ""}
              </a>
            </p>
          </div>
        </div>
      </LoadScript>
    </div>
  );
}

export default App;
