import React, { useReducer } from "react";
import {
  Marker,
  Polygon,
  Polyline,
  Circle,
  Rectangle,
  DrawingManager,
} from "@react-google-maps/api";

import { genGUID } from "./util";
import {
  DrawElementConst,
  ElementColorMap,
  FIELD_TEXT_ZOOM_LEVEL,
  GoogleDrawType,
  MAP_Zoom_Levels,
  MARKER_TEXT_ZOOM_LEVEL,
  PIT_OBS_TEXT_ZOOM_LEVEL,
  POLYLINE_ICON_ZOOM_LEVEL,
  ToolTypes,
  ToolsButtons,
  UNDO_REDO_CONSTANTS,
  getFirstSegmentDistance,
  getPolylineArrowOffset,
  getTotalDistanceInPixcels,
} from "./mapUtil";
import { Images } from "./assets";
const terfHelper = require("@turf/helpers");
const terfIntersect = require("@turf/intersect");
const terfLineIntersect = require("@turf/line-intersect");
const SELECTED_COLOR = "rgb(238, 179, 16)";

const MapElements = React.forwardRef(
  (
    {
      drawType,
      drawData,
      drawManager,
      readOnly,
      onChangeComplete,
      onDrawManage,
      onSelected,
      onDeleteItemById,
      selectedGroup,
      selectedItem,
      deletedItemId,
      mode,
      map,
      onShowWarning,
      isAnyGroupVisible,
    },
    ref
  ) => {
    const [selectedItems, setSelectedItems] = React.useState(null);
    const [tempDrawData, setTempDrawData] = React.useState(drawData);
    const [loaded, setLoaded] = React.useState([]);
    const [loadedTextNodes, setLoadedTextNodes] = React.useState([]);
    const [drawings, setDrawings] = React.useState([]);
    const [drawMode, setDrawMode] = React.useState(DrawElementConst.EDIT);
    const [colorMap, setColorMap] = React.useState(ElementColorMap);

    const [undoStack, setUndoStack] = React.useState([]);
    const [redoStack, setRedoStack] = React.useState([]);
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

    const hIntervalRef = React.useRef(null);
    // Define refs for Polygon instance
    const itemRef = React.useRef(null);

    React.useImperativeHandle(ref, () => ({
      onSelectItem(element) {
        onSelectedItem(null, element);
      },
      onApplyUndoRedo(actionItem) {
        setUndoRedoAction(actionItem);
      },
      onGroupVisibilityChange(group) {
        groupVisibilityChanged(group);
      },
      clearUndoRedoStacks() {
        setUndoStack([]);
        setRedoStack([]);
      },
      updateBeforeEditItem(item) {
        return;
      },
      onZoomChange({ previousZoom, currentZoom }) {
        setLabelByZoomLevel();
        if (!!currentZoom) {
          const strokeWidthFraction = MAP_Zoom_Levels[currentZoom];
          setStrokeWidth(strokeWidthFraction);
        }
      },
    }));

    React.useEffect(() => {
      if (!!itemRef.current) {
        const selected = drawData.find((x) => x.id === itemRef.current.id);
        if (!selected) {
          itemRef.current = null;
        }
      }
      loadedTextNodes.forEach((textNode) => textNode.setMap(null));
      setLoadedTextNodes([]);
      setLoaded([]);
      setTempDrawData([]);
      setTimeout(() => {
        drawData.forEach((marker) => {
          if (marker.type === GoogleDrawType.MARKER) {
            marker.label = null;
            marker.draggable = !readOnly;
          } else {
            marker.draggable = false;
          }
        });
        setTempDrawData(drawData);
      });
    }, [drawData]);

    React.useEffect(() => {
      deleteItemById(deletedItemId);
    }, [deletedItemId]);

    React.useEffect(() => {
      setDrawMode(mode);
      unselectSelected();
      setSelectedItems(selectedItem);

      if (mode === DrawElementConst.GROUP) {
        if (!!selectedGroup && selectedGroup.state === DrawElementConst.EDIT) {
          clearInterval(hIntervalRef.current);
          hIntervalRef.current = null;
          highlightElements(selectedGroup);
        } else {
          if (selectedGroup === selectedItem) {
            stopHighlightElements(selectedGroup);
          }
        }
        tempDrawData.forEach((x) => (x.draggable = false));
        if (!!selectedItem) {
          const selected = tempDrawData.find((x) => x.selected);
          if (selected) {
            const loadedPrevSelected = loaded.find(
              (x) => x.strokeColor === SELECTED_COLOR
            );
            if (
              loadedPrevSelected &&
              loadedPrevSelected.type !== GoogleDrawType.MARKER
            ) {
              // loadedPrevSelected.strokeColor =
              //   colorMap[previousSelected.drawType];
            }
            const loadedSelected = loaded.find((x) => x.id === selected.id);
            if (
              loadedSelected &&
              loadedSelected.type !== GoogleDrawType.MARKER
            ) {
              if (loadedSelected) {
                loadedSelected.strokeColor = SELECTED_COLOR;
              }
            }
          }
        } else {
          const loadedPrevSelected =
            loaded && loaded.find((x) => x.strokeColor === SELECTED_COLOR);
          if (
            loadedPrevSelected &&
            loadedPrevSelected.type !== GoogleDrawType.MARKER
          ) {
            if (loadedPrevSelected) {
              // loadedPrevSelected.strokeColor =
              //   colorMap[previousSelected.drawType];
            }
          }
        }
      } else {
        tempDrawData
          .filter((item) => item.type === GoogleDrawType.MARKER)
          .forEach((x) => (x.draggable = true));
        if (!!selectedItem) {
          setTimeout(() => {
            const selected = loaded.find((x) => x.id === selectedItem.id);
            if (selected) {
              if (selected.type !== GoogleDrawType.MARKER) {
                selected.setEditable(true);
              } else {
                selected.setOptions({
                  icon: {
                    url: Images[`${selected.img}_ON`],
                    labelOrigin: new window.google.maps.Point(15, -10),
                  },
                });
              }
            }
            setLabelByZoomLevel();
          });
        }
      }
    }, [mode, loaded, selectedGroup, selectedItem]);

    function setLabelByZoomLevel() {
      if (map === null || !map.zoom) {
        return;
      }
      showHideIconsText();
      showHideFieldsText();
      showHidePitsAndObstaclesText();
      showHideStrokeIcons();
    }

    function showHideIconsText() {
      const labelIcons = tempDrawData.filter(
        (x) => x.drawType === ToolTypes.OTHER || x.drawType === ToolTypes.PUMP
      );
      if (map.zoom > MARKER_TEXT_ZOOM_LEVEL) {
        labelIcons.forEach((lItem) => {
          lItem.label = {
            text: lItem.name,
            color: "white",
          };
          const labelLoaded = loaded.find((xx) => xx.id === lItem.id);
          labelLoaded.setLabel({
            text: lItem.name,
            color: "white",
          });
        });
      } else {
        labelIcons.forEach((lItem) => {
          lItem.label = null;
          const labelLoaded = loaded.find((xx) => xx.id === lItem.id);
          labelLoaded.setLabel(null);
        });
      }
    }

    function showHideFieldsText() {
      const fields = loadedTextNodes.filter(
        (x) => x.drawType === ToolTypes.FIELD
      );
      if (map.zoom > FIELD_TEXT_ZOOM_LEVEL) {
        fields.forEach((txtNode) => {
          txtNode.setVisible(true);
        });
      } else {
        fields.forEach((txtNode) => {
          txtNode.setVisible(false);
        });
      }
    }

    function showHidePitsAndObstaclesText() {
      const pitsObs = loadedTextNodes.filter(
        (x) => x.drawType === ToolTypes.OBSTACLE || x.drawType === ToolTypes.PIT
      );
      if (map.zoom > PIT_OBS_TEXT_ZOOM_LEVEL) {
        pitsObs.forEach((txtNode) => {
          txtNode.setVisible(true);
        });
      } else {
        pitsObs.forEach((txtNode) => {
          txtNode.setVisible(false);
        });
      }
    }

    function showHideStrokeIcons() {
      const strokes = loaded.filter((x) => x.type === GoogleDrawType.POLYLINE);
      if (map.zoom > POLYLINE_ICON_ZOOM_LEVEL) {
        strokes.forEach((strk) => {
          const symbol = strk.icons && strk.icons[0] && strk.icons[0].icon;
          if (symbol) {
            symbol.fillOpacity = 1;
            symbol.strokeOpacity = 1;
            symbol.strokeColor = "black";
            symbol.strokeWeight = 2;
            symbol.scale = 5;
          }
        });
      } else {
        strokes.forEach((strk) => {
          const symbol = strk.icons && strk.icons[0] && strk.icons[0].icon;
          if (symbol) {
            symbol.fillOpacity = 0;
            symbol.strokeOpacity = 0;
            symbol.strokeColor = "transparent";
            symbol.strokeWeight = 0;
            symbol.scale = 0;
          }
        });
      }
    }

    function setStrokeWidth(fraction) {
      const toBeChanged = loaded.filter(
        (x) => x.type === GoogleDrawType.POLYLINE
      );
      toBeChanged.forEach((poly) => {
        let final =
          fraction !== undefined ? poly.strokeWeight - fraction / 100 : 1;
        if (final > 1) {
          poly.setOptions({ strokeWeight: final });
        }
        // poly.setOptions({ strokeWeight: final });
      });
      //setLoaded(loaded);
      forceUpdate();
    }

    function deleteItemById(deletedItemId) {
      let deleteItem;
      let deleteDataItem;
      const deletedItemIndex = loaded.findIndex(
        (item) => item.id === deletedItemId
      );
      if (deletedItemIndex > -1) {
        deleteItem = loaded[deletedItemIndex];
        loaded.splice(deletedItemIndex, 1);
        const deleteTextNodeInex = loadedTextNodes.findIndex(
          (item) => item.id === `${deletedItemId}_text`
        );
        if (deleteTextNodeInex > -1) {
          loadedTextNodes[deleteTextNodeInex].setMap(null);
          loadedTextNodes.splice(deleteTextNodeInex, 1);
        }
        const slIndex = tempDrawData.findIndex((x) => x.id === deletedItemId);
        if (slIndex > -1) {
          deleteDataItem = tempDrawData[slIndex];
          tempDrawData.splice(slIndex, 1);
          setTempDrawData(tempDrawData);
          onChangeComplete(tempDrawData, {
            undoStack,
            redoStack,
          });
        }

        const lastAction = {
          action: UNDO_REDO_CONSTANTS.REMOVE,
          item: deleteItem,
          dataItem: deleteDataItem,
        };
        setUndoStack([...undoStack, lastAction]);
      }
    }

    function groupVisibilityChanged(groupVisibility) {
      if (!!groupVisibility) {
        setTimeout(() => {
          loaded
            .filter(
              (lItem) =>
                lItem.group &&
                lItem.group.find((groupId) => groupId === groupVisibility.id)
            )
            .forEach((lItem) => {
              const dataItem = tempDrawData.find(
                (tempDataItem) => tempDataItem.id === lItem.id
              );
              const textNode = loadedTextNodes.find(
                (textNode) => textNode.id === `${lItem.id}_text`
              );
              if (lItem.group.length > 1) {
                const otherGroupIds = lItem.group.filter(
                  (groupId) => groupId !== groupVisibility.id
                );
                const found = isAnyGroupVisible(otherGroupIds);
                lItem.setVisible(found || !groupVisibility.visible);
                dataItem.visible = found || !groupVisibility.visible;
                textNode &&
                  textNode.setVisible(found || !groupVisibility.visible);
              } else {
                lItem.setVisible(!groupVisibility.visible);
                dataItem.visible = !groupVisibility.visible;
                textNode && textNode.setVisible(!groupVisibility.visible);
              }
            });
        });
        setTempDrawData(tempDrawData);
      }
    }

    function setUndoRedoAction(actionItem) {
      let nextPrevState;
      if (actionItem === UNDO_REDO_CONSTANTS.UNDO) {
        nextPrevState = undoStack.pop();
      } else {
        nextPrevState = redoStack.pop();
      }
      if (nextPrevState) {
        setPresentStateDataByAction(nextPrevState, actionItem);
      }
    }

    function setPresentStateDataByAction(actionItem, undoRedoFlag) {
      const { action, item, dataItem } = actionItem;
      const lastAction = {
        action: action,
        item: {
          id: item.id,
          path: null,
          position: null,
        },
      };

      const itemLoadedIndex = loaded.findIndex((lItem) => lItem.id === item.id);
      const dataItemIndex = tempDrawData.findIndex(
        (drawData) => drawData.id === item.id
      );
      if (dataItemIndex > -1) {
        const itemLoaded = loaded[itemLoadedIndex];
        switch (itemLoaded.type) {
          case GoogleDrawType.POLYGON:
            if (
              action === UNDO_REDO_CONSTANTS.ADD ||
              action === UNDO_REDO_CONSTANTS.REMOVE
            ) {
              lastAction.item = loaded[itemLoadedIndex];
              lastAction.dataItem = tempDrawData[dataItemIndex];
              loaded.splice(itemLoadedIndex, 1);
              const textNodeIndex = loadedTextNodes.findIndex(
                (textNode) => textNode.id === `${itemLoaded.id}_text`
              );
              if (textNodeIndex > -1) {
                loadedTextNodes[textNodeIndex].setMap(null);
                loadedTextNodes.splice(textNodeIndex, 1);
              }
              if (dataItemIndex > -1) {
                tempDrawData.splice(dataItemIndex, 1);
              }
              setLoadedTextNodes(loadedTextNodes);
            } else if (action === UNDO_REDO_CONSTANTS.UPDATE_ITEM) {
              itemLoaded.setOptions({ ...item });
              lastAction.item = { ...lastAction.item, ...item };
              tempDrawData[dataItemIndex] = {
                ...tempDrawData[dataItemIndex],
                ...item,
              };
            } else {
              itemLoaded.setPaths(item.path);
              lastAction.item.path = tempDrawData[dataItemIndex].path;
              tempDrawData[dataItemIndex].path = item.path;
              resetTextNode(itemLoaded, tempDrawData[dataItemIndex], item.path);
            }
            break;
          case GoogleDrawType.POLYLINE:
            if (
              action === UNDO_REDO_CONSTANTS.ADD ||
              action === UNDO_REDO_CONSTANTS.REMOVE
            ) {
              lastAction.item = loaded[itemLoadedIndex];
              lastAction.dataItem = tempDrawData[dataItemIndex];
              loaded.splice(itemLoadedIndex, 1);
              if (dataItemIndex) {
                tempDrawData.splice(dataItemIndex, 1);
              }
            } else if (action === UNDO_REDO_CONSTANTS.UPDATE_ITEM) {
              itemLoaded.setOptions({ ...item });
              lastAction.item = { ...lastAction.item, ...item };
              tempDrawData[dataItemIndex] = {
                ...tempDrawData[dataItemIndex],
                ...item,
              };
            } else {
              itemLoaded.setPath(item.path);
              lastAction.item.path = tempDrawData[dataItemIndex].path;
              tempDrawData[dataItemIndex].path = item.path;
            }
            break;
          case GoogleDrawType.MARKER:
            if (
              action === UNDO_REDO_CONSTANTS.ADD ||
              action === UNDO_REDO_CONSTANTS.REMOVE
            ) {
              lastAction.item = loaded[itemLoadedIndex];
              lastAction.dataItem = tempDrawData[dataItemIndex];
              loaded.splice(itemLoadedIndex, 1);
              if (dataItemIndex) {
                tempDrawData.splice(dataItemIndex, 1);
              }
            } else if (action === UNDO_REDO_CONSTANTS.UPDATE_ITEM) {
              itemLoaded.setOptions({ ...item });
              lastAction.item = { ...lastAction.item, ...item };
              tempDrawData[dataItemIndex] = {
                ...tempDrawData[dataItemIndex],
                ...item,
              };
            } else {
              itemLoaded.setPosition(item.position);
              lastAction.item.position = tempDrawData[dataItemIndex].position;
              tempDrawData[dataItemIndex].position = item.position;
            }
            break;
        }
      } else {
        lastAction.item = item;
        lastAction.dataItem = dataItem;
        if (
          action === UNDO_REDO_CONSTANTS.ADD ||
          action === UNDO_REDO_CONSTANTS.REMOVE
        ) {
          if (dataItem) {
            tempDrawData.push(dataItem);
          }
        }
      }
      if (undoRedoFlag === UNDO_REDO_CONSTANTS.UNDO) {
        setRedoStack([...redoStack, lastAction]);
      } else {
        setUndoStack([...undoStack, lastAction]);
      }
      setLoaded(loaded);
      setTempDrawData(tempDrawData);
      onChangeComplete(tempDrawData, {
        undoStack,
        redoStack,
      });
    }

    function highlightElements(group) {
      hIntervalRef.current = setInterval(() => {
        loaded
          .filter((x) => x.group.find((groupId) => groupId === group.id))
          .forEach((x) => {
            if (x.getVisible()) {
              x.setVisible(false);
            } else {
              x.setVisible(true);
            }
          });
      }, 500);
    }

    function stopHighlightElements(group) {
      if (!!group) {
        clearInterval(hIntervalRef.current);
        hIntervalRef.current = null;
        loaded
          .filter((x) => x.group.find((groupId) => groupId === group.id))
          .forEach((x) => {
            const otherGroupIds = x.group.filter(
              (groupId) => groupId !== group.id
            );
            const found = isAnyGroupVisible(otherGroupIds);
            x.setVisible(found || !group.visible);
          });
      }
    }

    const onEdit = (e) => {
      if (!!selectedItems) {
        const loadedItem = loaded.find((item) => item.id === selectedItems.id);
        if (loadedItem) {
          // TODO remove this after confirming with client
          updatedLoadedItems(e);
        }
      }
    };

    function updatedLoadedItems(event, updatedItem) {
      const item = !!event ? itemRef.current : updatedItem;
      let lastAction;
      if (item) {
        const selectedItem = tempDrawData.find((x) => x.id === item.id);
        if (
          item.type === GoogleDrawType.POLYGON ||
          item.type === GoogleDrawType.POLYLINE
        ) {
          lastAction = {
            action: UNDO_REDO_CONSTANTS.UPDATE,
            item: {
              id: selectedItem.id,
              path: JSON.parse(JSON.stringify(selectedItem.path)),
            },
          };
          const nextPath = item
            .getPath()
            .getArray()
            .map((latLng) => {
              return { lng: latLng.lng(), lat: latLng.lat() };
            });
          if (!isPathEquals(nextPath, selectedItem.path)) {
            resetTextNode(item, selectedItem, nextPath);
            setUnDoAction(lastAction);
            onChangeComplete(tempDrawData, {
              undoStack,
              redoStack,
            });
          }
        } else if (item.type === GoogleDrawType.MARKER) {
          lastAction = {
            action: UNDO_REDO_CONSTANTS.UPDATE,
            item: {
              id: selectedItem.id,
              position: JSON.parse(JSON.stringify(selectedItem.position)),
            },
          };
          const nextPosition = {
            lng: item.getPosition().lng(),
            lat: item.getPosition().lat(),
          };
          if (!isPathEquals([nextPosition], [selectedItem.position])) {
            selectedItem.position = nextPosition;
            setUnDoAction(lastAction);
            onChangeComplete(tempDrawData, {
              undoStack,
              redoStack,
            });
          }
        }
      }
    }

    function isPathEquals(path1, path2) {
      if (path1.length !== path2.length) {
        return false;
      } else {
        path1 = path1.sort();
        path2 = path2.sort();
        for (let i = 0; i < path1.length; i++) {
          const loc1 = new window.google.maps.LatLng(path1[i]);
          const loc2 = new window.google.maps.LatLng(path2[i]);
          if (!loc1.equals(loc2)) {
            return false;
            break;
          }
        }
        return true;
      }
    }

    function setUnDoAction(lastAction) {
      setUndoStack([...undoStack, lastAction]);
    }

    function resetTextNode(item, selectedDataItem, nextPath) {
      selectedDataItem.path = nextPath;
      if (item.textNode) {
        const getTxtNode = loadedTextNodes.find(
          (element) => element.id === `${item.id}_text`
        );
        if (getTxtNode) {
          const itemBound = getBounds(selectedDataItem.path);
          getTxtNode.setPosition({
            lat: itemBound.getCenter().lat(),
            lng: itemBound.getCenter().lng(),
          });
          selectedDataItem.textNode = {
            center: {
              lat: itemBound.getCenter().lat(),
              lng: itemBound.getCenter().lng(),
            },
          };
        }
      }
    }

    const onloadedMarkers = (item) => {
      item.label = {
        text: item.name,
        color: "white",
      };
      loaded.push(item);
      setLoaded(loaded);
    };

    const onloadedPolygons = (item) => {
      const dataItem = tempDrawData.find((x) => x.id === item.id);
      const itemBound = getBounds(dataItem.path);
      dataItem.textNode = {
        center: {
          lat: itemBound.getCenter().lat(),
          lng: itemBound.getCenter().lng(),
        },
      };
      const newTextNode = new window.google.maps.Marker({
        position: dataItem.textNode.center,
        label: {
          text: item.name,
          color: "#ffffff",
        },
        icon: Images["LBL_IMG_ICO"],
        id: `${item.id}_text`,
        drawType: item.drawType,
      });
      newTextNode.addListener("mousedown", (e) => {
        const dataItem = tempDrawData.find((x) => x.id === item.id);
        onSelectedItem(e, dataItem);
      });
      newTextNode.setMap(map);
      if (!item.visible) {
        newTextNode.setVisible(false);
      }
      loadedTextNodes.push(newTextNode);

      loaded.push(item);
      setLoadedTextNodes(loadedTextNodes);
      setLoaded(loaded);
    };

    const onLoadSelected = (item) => {
      loaded.push(item);
      setLoaded(loaded);
    };

    const onSelectedItem = (e, item) => {
      // if (drawManager.getDrawingMode()) {
      //   return;
      // }
      unselectSelected();
      if (item) {
        itemRef.current = loaded.find((x) => x.id === item.id);
        if (readOnly) {
          return;
        } else {
          if (itemRef.current) {
            if (mode !== DrawElementConst.GROUP) {
              loaded.forEach((x) => {
                x.setDraggable(false);
              });
              if (itemRef.current.type !== GoogleDrawType.MARKER) {
                itemRef.current.setEditable(true);
              }
              itemRef.current.setDraggable(false);
            } else {
              item.draggable = false;
              itemRef.current.setDraggable(false);
            }
            // set selected marker
            if (itemRef.current.type === GoogleDrawType.MARKER) {
              item.icon = {
                url: Images[`${item.img}_ON`],
                labelOrigin: new window.google.maps.Point(15, -10),
              };
            }
          }
        }
        setSelectedItems(item);
        onSelected(item);
      }
    };

    function unselectSelected() {
      const preSelecteds = loaded.filter((x) => x.selected);
      preSelecteds.forEach((selected) => {
        const dataItem = tempDrawData.find((dataI) => dataI.id === selected.id);
        const options = { selected: false };
        if (selected.type === GoogleDrawType.MARKER) {
          dataItem.icon = {
            url: Images[`${selected.img}_OFF`],
            labelOrigin: new window.google.maps.Point(15, -10),
          };
        } else {
          dataItem.editable = false;
        }
        dataItem.selected = false;
      });
    }

    const onLoadDrawManager = (drawingManager) => {
      onDrawManage(drawingManager);
    };

    const onPolygonComplete = (polygon) => {
      try {
        checkExactPolygon(polygon, "create");
        polygon.__type = drawType;
        drawings.push(polygon);
        setDrawings(drawings);
        makeDataObjects();
        drawManager.setDrawingMode(null);
      } catch (e) {
        onShowWarning({
          polygon: true,
        });
        polygon.setMap(null);
        onChangeComplete(null, {});
        drawManager.setDrawingMode(null);
        return;
      }
    };

    function checkExactPolygon(polygon, mode) {
      try {
        // just check polygon has 3 or more nodes
        const newItemPos = getPathPolyItems(polygon);
        if (mode === "removeVertex") {
          // const fifth = newItemPos[4];
          // fifth[0];
        }
        terfHelper.polygon([newItemPos]);
      } catch (e) {
        throw "polygons should have more than 2 nodes";
      }
    }

    const onPolylineComplete = (polyline) => {
      polyline.__type = drawType;
      drawings.push(polyline);
      setDrawings(drawings);
      makeDataObjects();
      drawManager.setDrawingMode(null);
    };

    const onMarkerComplete = (marker) => {
      marker.__type = drawType;
      drawings.push(marker);
      setDrawings(drawings);
      makeDataObjects();
      drawManager.setDrawingMode(null);
    };

    function checkMarkerInsideFixedItems(marker) {
      const fixedInMap = loaded.filter(
        (loadItem) =>
          loadItem.drawType === ToolTypes.OBSTACLE ||
          loadItem.drawType === ToolTypes.PIT
      );
      if (fixedInMap.length === 0) {
        return undefined;
      }
      const markerPoint = marker.getPosition();
      return fixedInMap.find((fixedItem) => {
        return window.google.maps.geometry.poly.containsLocation(
          markerPoint,
          fixedItem
        );
      });
    }

    function getPathPolyItems(polygon) {
      const polyPostion = polygon
        .getPath()
        .getArray()
        .map((x) => {
          return [x.lat(), x.lng()];
        });
      if (polyPostion[0] !== polyPostion[polyPostion.length - 1]) {
        polyPostion.push(polyPostion[0]);
      }
      return polyPostion;
    }

    const removePoint = (event) => {
      const item = itemRef.current;

      if (
        item.type === GoogleDrawType.POLYGON ||
        item.type === GoogleDrawType.POLYLINE
      ) {
        if (item.type === GoogleDrawType.POLYGON) {
          try {
            checkExactPolygon(item, "removeVertex");
          } catch (e) {
            onShowWarning({
              polygon: true,
            });
            return;
          }
        }
        const path = item.getPath();
        if (event.vertex != undefined) {
          if (path.length > 2) {
            path.removeAt(event.vertex);
            itemRef.current = item;
            updatedLoadedItems(event);
          } else {
            onDeleteItemById(item.id);
          }
        }
      }
    };

    function makeDataObjects() {
      const saveData = drawData;
      let lastAction;
      drawings.forEach((item) => {
        let coords = {};
        coords.id = genGUID();
        if (item.__type === GoogleDrawType.MARKER) {
          coords.position = {
            lat: item.getPosition().lat(),
            lng: item.getPosition().lng(),
          };
        } else if (
          item.__type === GoogleDrawType.POLYLINE ||
          item.__type === GoogleDrawType.POLYGON
        ) {
          coords.path = item
            .getPath()
            .getArray()
            .map((x) => {
              return {
                lat: x.lat(),
                lng: x.lng(),
              };
            });
          if (
            item.drawType === ToolTypes.FIELD ||
            item.drawType === ToolTypes.PIT ||
            item.drawType === ToolTypes.OBSTACLE
          ) {
            const itemBound = getBounds(coords.path);
            coords.textNode = {
              center: {
                lat: itemBound.getCenter().lat(),
                lng: itemBound.getCenter().lng(),
              },
            };
          }
        }

        // get options
        const index = findObhectIndex(item.drawType);
        const label = item.name || item.drawType + "(" + index + ")";
        coords.type = item.__type;
        coords.zIndex = findZIndex(item.drawType);
        coords.drawType = item.drawType;
        coords.name = label;
        coords.group = [];
        coords.strokeColor = item.strokeColor;
        coords.actualStrokeWeight = item.actualStrokeWeight;
        coords.strokeWeight = item.strokeWeight;
        coords.strokeOpacity = 1;
        coords.visible = item.visible;
        coords.clickable = true;
        coords.draggable = false;
        coords.groupable = item.groupable;
        coords.calulatedValues = {
          totalArea: null,
          length: null,
          elevation: null,
          diameter: null,
          eleAndLengthChartData: null,
        };
        coords.img = ToolsButtons.find((btn) => btn.name === item.drawType).img;
        if (item.fillColor) {
          coords.fillColor = item.fillColor;
        }
        if (item.fillOpacity) {
          coords.fillOpacity = item.fillOpacity;
        }
        if (item.__type === GoogleDrawType.MARKER) {
          coords.icon = {
            url: Images[`${item.icon.replace("_ON", "_OFF")}`],
            labelOrigin: new window.google.maps.Point(15, -10),
          };
        } else if (item.__type === GoogleDrawType.POLYLINE) {
          const path = item.getPath(); // Replace `polyline` with your actual polyline object
          const pixelPath = path.getArray();
          const computeOffset = getPolylineArrowOffset(pixelPath, map, 30);
          coords.strokeOpacity = 1;
          coords.icons = [
            {
              icon: {
                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                strokeColor: "black",
                fillColor: item.strokeColor,
                fillOpacity: 1,
                strokeWeight: 2,
                strokeOpacity: 1,
                scale: 5,
              },
              offset: computeOffset,
            },
          ];
        }
        saveData.push(coords);
        lastAction = {
          action: UNDO_REDO_CONSTANTS.ADD,
          item: {
            id: coords.id,
          },
        };
      });
      drawings.forEach((x) => {
        x.setMap(null);
      });
      setUnDoAction(lastAction);
      onChangeComplete(saveData, {
        undoStack,
        redoStack,
      });
      setDrawings([]);
      setLabelByZoomLevel();
    }

    function findZIndex(type) {
      switch (type) {
        case "field":
          const fieldIndex = tempDrawData.filter(
            (drawItem) => drawItem.drawType === type
          );
          fieldIndex.sort((a, b) => a.zIndex - b.zIndex);
          return fieldIndex.length === 0
            ? 1000
            : fieldIndex[fieldIndex.length - 1].zIndex - 1;
        default:
          const otherIndex = tempDrawData.filter(
            (drawItem) => drawItem.drawType !== "field"
          );
          otherIndex.sort((a, b) => a.zIndex - b.zIndex);
          return otherIndex.length === 0
            ? 1001
            : otherIndex[otherIndex.length - 1].zIndex + 1;
      }
    }

    function findObhectIndex(type) {
      const zIndexs = tempDrawData.filter(
        (drawItem) => drawItem.drawType === type
      );
      return zIndexs.length + 1;
    }

    const getBounds = (item) => {
      const bounds = new window.google.maps.LatLngBounds();
      item.forEach((element, index) => {
        bounds.extend(element);
      });
      return bounds;
    };

    return (
      <>
        {tempDrawData &&
          tempDrawData.map((marker, index) => {
            if (marker.type === GoogleDrawType.MARKER)
              return (
                <Marker
                  key={`marker_${index.toString()}`}
                  onLoad={onloadedMarkers}
                  position={marker.position}
                  onMouseDown={(e) => onSelectedItem(e, marker)}
                  onMouseUp={onEdit}
                  onDragEnd={onEdit}
                  options={{ ...marker }}
                />
              );
            else if (marker.type === GoogleDrawType.POLYLINE)
              return (
                <Polyline
                  key={marker.id}
                  onLoad={onLoadSelected}
                  path={marker.path}
                  onMouseDown={(e) => onSelectedItem(e, marker)}
                  onMouseUp={onEdit}
                  onDragEnd={onEdit}
                  onDrag={() => false}
                  onDblClick={removePoint}
                  options={{ ...marker }}
                />
              );
            else if (marker.type === GoogleDrawType.POLYGON)
              return (
                <Polygon
                  key={marker.id}
                  onLoad={onloadedPolygons}
                  path={marker.path}
                  onMouseDown={(e) => onSelectedItem(e, marker)}
                  onMouseUp={onEdit}
                  onDrag={() => false}
                  onDragEnd={onEdit}
                  onDblClick={removePoint}
                  options={{ ...marker }}
                />
              );
            else if (marker.type === GoogleDrawType.CIRCLE)
              return (
                <Circle
                  key={marker.id}
                  onLoad={onLoadSelected}
                  center={marker.center}
                  radius={marker.radius}
                  onMouseDown={(e) => onSelectedItem(e, marker)}
                  onMouseUp={onEdit}
                  onDragEnd={onEdit}
                  options={{ ...marker }}
                />
              );
            else if (marker.type === GoogleDrawType.RECTANGLE)
              return (
                <Rectangle
                  key={marker.id}
                  onLoad={onLoadSelected}
                  bounds={marker.bounds}
                  onMouseDown={(e) => onSelectedItem(e, marker)}
                  onMouseUp={onEdit}
                  onDragEnd={onEdit}
                  options={{ ...marker }}
                />
              );
          })}
        <DrawingManager
          onLoad={onLoadDrawManager}
          onPolylineComplete={onPolylineComplete}
          onMarkerComplete={onMarkerComplete}
          onPolygonComplete={onPolygonComplete}
          onRectangleComplete={onPolygonComplete}
          onCircleComplete={onPolygonComplete}
          options={{ drawingControl: false }}
        />
      </>
    );
  }
);

export default MapElements;
