const calcDistance = (p1, p2) => {
  return window.google.maps.geometry.spherical
    .computeDistanceBetween(p1, p2)
    .toFixed(2);
};

const roundWithDecimals = (value, decimal) => {
  const tepm = Number(Math.round(value + "e" + decimal) + "e-" + decimal);
  return tepm;
};

const getDistanceByPosition = (args) => {
  const resultArr = [];

  for (const index of args.keys()) {
    if (index === args.length - 1) {
      break;
    }
    const location_2 = args[index + 1];
    if (location_2) {
      const location_1 = args[index];
      const results = parseFloat(calcDistance(location_1, location_2), 10);
      const result = {
        location_1,
        location_2,
        results,
      };
      resultArr.push(result);
    }
  }
  return resultArr;
};

const getAreaByPosition = (args) => {
  const latLngs = [];
  args.forEach((position) => {
    latLngs.push(position);
  });
  let computedArea;
  try {
    computedArea = window.google.maps.geometry.spherical.computeArea(latLngs);
  } catch (e) {
    console.error("can not calculate");
  }
  return computedArea;
};

export const DistanceUtil = {
  getDistanceByPosition,
  getAreaByPosition,
  roundWithDecimals,
};
