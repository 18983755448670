export const IMAGE_PATH = "./images";
///Users/mithun/test/SpadesAdminMobileApp/src/assets/images/spadesapp_white.png
export const Images = {
  FIELD_ICO: require(`${IMAGE_PATH}/tool_icon/Field-ico.png`),
  HOSE_ICO: require(`${IMAGE_PATH}/tool_icon/Hose-ico.svg`),
  OBSTRACLE_ICO: require(`${IMAGE_PATH}/tool_icon/Obstracle-ico.png`),
  PIPE_LINE_ICO: require(`${IMAGE_PATH}/tool_icon/pipe-line-ico.png`),
  PIT_ICO: require(`${IMAGE_PATH}/tool_icon/Pit-ico.png`),
  CULVERT_ICO: require(`${IMAGE_PATH}/tool_icon/Culvert-ico.svg`),
  CULVERT_ICO_OFF: require(`${IMAGE_PATH}/tool_icon/Culvert.png`),
  CULVERT_ICO_ON: require(`${IMAGE_PATH}/tool_icon/Culvert_On.png`),
  PUMP_ICO: require(`${IMAGE_PATH}/tool_icon/Pump.png`),
  PUMP_ICO_OFF: require(`${IMAGE_PATH}/tool_icon/Pump.png`),
  PUMP_ICO_ON: require(`${IMAGE_PATH}/tool_icon/Pump_On.png`),
  RISER_PIPE_ICO: require(`${IMAGE_PATH}/tool_icon/Riser-Pipe-Ico.svg`),
  RISER_PIPE_ICO_OFF: require(`${IMAGE_PATH}/tool_icon/Riser.png`),
  RISER_PIPE_ICO_ON: require(`${IMAGE_PATH}/tool_icon/Riser_On.png`),
  SET_LAYOUT_ICO: require(`${IMAGE_PATH}/tool_icon/Set-Layout-ico.png`),
  LBL_IMG_ICO: require(`${IMAGE_PATH}/label_marker.png`),
  OTHER_ICO: require(`${IMAGE_PATH}/tool_icon/Other.png`),
  OTHER_ICO_OFF: require(`${IMAGE_PATH}/tool_icon/Other-icon.png`),
  OTHER_ICO_ON: require(`${IMAGE_PATH}/tool_icon/Other-icon_On.png`),
};
